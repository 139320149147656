<template>
  <div>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row">
          <div class="col-md-1">
            <div class="form-group mb-md-0">
              <input type="tel" v-model="filters.id" placeholder="ID" class="form-control" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <input type="tel" v-model="filters.company" placeholder="Fornecedor" class="form-control" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <v-select label="title" :searchable="false" :clearable="true" item-text="title" item-value="code"
                :options="optionsStatus" v-model="filters.status" placeholder="Status">
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <v-select label="title" :clearable="true" item-text="title" item-value="code"
                :options="optionsBankAccounts" v-model="filters.bank_account" placeholder="Conta bancária">
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <flat-pickr v-model="filters.period" class="form-control" placeholder="Período de vencimento" :config="{
                mode: 'range',
                altInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                locale: 'pt',
              }" />
            </div>
          </div>
          <div class="col-md-1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <div class="form-row justify-content-end mb-2">
      <div class="col-md-3">
        Ao aprovar uma baixa, o mesmo será liberado para ser dado baixa.
      </div>
      <div class="col-md-2">
        <button :disabled="selectedItems.length < 1 ? true : false" type="button"
          class="btn btn-sm btn-success btn-block" @click="updateInstallmentStatus(3)">
          <i v-b-tooltip.hover.top="'Dar baixa'" style="font-size: 1.2rem; line-height: 1.5;"
            class="bi bi-receipt-cutoff cursor-pointer cursor mr-1"></i>
          Aprovar baixa
        </button>
      </div>
    </div>
    <b-card no-body>
      <b-table sticky-header :items="dataItems" responsive :fields="tableColumns" primary-key="id" show-empty
        empty-text="Nenhum registro encontrado" class="position-relative">
        <template #head(select)>
          <b-form-checkbox v-model="selectAllCheckbox" @change="toggleSelectAll" />
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox v-if="item.purchase_order_installment_status_name === 'Análise'"
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto;" :value="item.uuid"
            v-model="selectedItems" />
        </template>

        <template #cell(supplier)="{ item }">
          <div class="strategic-units" v-if="item.supplier">
            <strong>{{ item.supplier.company_name }}</strong>
            <div>{{ item.supplier.document }}</div>
          </div>
          <div class="strategic-units" v-else>
            <strong>{{ item.customer.title }}</strong>
            <div>{{ toMaskCpf(item.customer.cpf) }}</div>
          </div>
        </template>

        <template #cell(installment)="{ item }">
          {{ item.installment }}/{{ item.installments }}
        </template>

        <template #cell(purchase_order_installment_status_name)="{ item }">
          <span
            :style="`background-color: ${item.purchase_order_installment_status_background}; color: ${item.purchase_order_installment_status_color};padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;`">
            {{ item.purchase_order_installment_status_name }}
          </span>
        </template>

        <template #cell(document)="{ item }">
          <div v-if="item.document"
            style="display: grid; align-content: center; justify-content: center; align-items: center;">
            <div>
              {{ item.document.number }}
            </div>
            <div>{{ item.document.title }}</div>
          </div>
        </template>

        <template #cell(plan)="{ item }">
          <div v-if="item.plan" class="d-block text-center">
            <strong>{{ item.plan.title }}</strong>
            <div v-if="item.document_type">{{ item.document_type.title }}</div>
          </div>
          <span v-else-if="item.order_id" class="text-danger">
            <strong class="d-block">Matrícula</strong>
            Cancelada
          </span>
        </template>

        <template #cell(date)="{ item }">
          {{ item.date | datePT() }}
        </template>

        <template #cell(total)="{ item }">
          {{ item.total | toCurrency }}
        </template>

        <template #cell(actions)="{ item }">
          <feather-icon
            @click="$router.push({ name: 'orders-purchases-print', params: { uuid: item.purchase_order_uuid } })"
            v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')" icon="EyeIcon" v-b-tooltip.hover.top="'Visualizar'"
            class="cursor-pointer cursor" size="16" />
        </template>
      </b-table>

      <CustomPaginateTable :rows-table="rowsTable" :current-page="filters.currentPage" :per-page="filters.perPage"
        :total-rows="totalRows" />
    </b-card>

    <b-modal id="modal-purchase-order-view" size="lg" modal-class="modal-create" title="Detalhes do pedido de compra"
      centered hide-footer>
      <div class=" bg-white p-2 rounded-1 justify-content-center mt-1 d-block">
        <h4 class="text-center">
          <div class="d-flex" style="align-content: center; justify-content: space-between; align-items: center;">
            <strong>ID {{ item.id }}</strong>
            <strong>PEDIDO DE COMPRA </strong>
            <strong>{{ item.created_at | datePT(true) }}</strong>
          </div>
        </h4>
        <hr>
        <div class="form-row">
          <div class="col-md-12">
            <h5 class="mb-1"><strong>DETALHES</strong></h5>
          </div>

          <div class="col-md-8">
            <p style="margin-bottom: 5px;">
              <strong>Tipo doc.</strong>
              {{ item.document_type.title }}
            </p>
            <p style="margin-bottom: 5px;">
              <strong> Categoria </strong>
              {{ item.category ? item.category.title : 'Nenhuma' }}
            </p>
            <p v-if="item.plan" style="margin-bottom: 5px;">
              <strong>Histórico PC</strong>
              {{ item.plan.title }}
            </p>
            <p style="margin-bottom: 5px;">
              <strong>Nº Documento</strong>
              {{ item.document_number }}
            </p>
            <p style="margin-bottom: 5px;">
              <strong>Status</strong>
              {{ item.status.title }}
            </p>
            <p style="margin-bottom: 5px;">
              <strong>Etapa</strong>
              {{ item.step.title }}
            </p>
          </div>

          <div class="col-md-4">
            <div v-if="item.bank_account">
              <h5>Conta bancária</h5>
              <p v-if="item.bank_account.title" style="margin-bottom: 5px;">
                <strong>Empresa:</strong> {{ item.bank_account.company }}
              </p>
              <p v-if="item.bank_account.title" style="margin-bottom: 5px;">
                <strong>Nome:</strong> {{ item.bank_account.title }}
              </p>
              <p v-if="item.bank_account.agency" style="margin-bottom: 5px;">
                <strong>Agência:</strong> {{ item.bank_account.agency }}
              </p>
              <p v-if="item.bank_account.account_number" style="margin-bottom: 5px;">
                <strong>Nº Conta:</strong> {{ item.bank_account.account_number }}
              </p>
              <p v-if="item.bank_account.bank_name" style="margin-bottom: 5px;">
                <strong>Banco:</strong> {{ item.bank_account.bank_name }}
              </p>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="col-md-12">
            <h5 class="mb-1"><strong>Fornecedor</strong></h5>
          </div>
        </div>

        <div v-if="item.supplier">
          <div class="form-row">
            <div class="col-md-5">
              <p style="margin-bottom: 5px;">
                {{ item.supplier.title }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ item.supplier.trading_name }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ item.supplier.document }}
              </p>
              <p style="margin-bottom: 5px;">
                Simples Nacional: {{ item.supplier.simple_national ? 'Sim' : 'Não' }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ item.supplier.type_company }}
              </p>
            </div>

            <div class="col-md-4">
              <h5>Contatos</h5>
              <p v-if="item.supplier.email" style="margin-bottom: 5px;">
                {{ item.supplier.email }}
              </p>
              <p v-if="item.supplier.whatsapp" style="margin-bottom: 5px;">
                {{ item.supplier.whatsapp }}
              </p>
              <p v-if="item.supplier.cellphone" style="margin-bottom: 5px;">
                {{ item.supplier.cellphone }}
              </p>
              <p v-if="item.supplier.phone" style="margin-bottom: 5px;">
                {{ item.supplier.phone }}
              </p>
            </div>

            <div class="col-md-3">
              <h5>Endereço</h5>
              <p v-if="item.supplier.address" style="margin-bottom: 5px;">
                {{ item.supplier.address }}
              </p>
              <p v-if="item.supplier.number" style="margin-bottom: 5px;">
                Nº {{ item.supplier.number }} {{ item.supplier.complement }}
              </p>
              <p v-if="item.supplier.neighborhood" style="margin-bottom: 5px;">
                {{ item.supplier.neighborhood }}, {{ item.supplier.city }}
              </p>
              <p v-if="item.supplier.zipcode" style="margin-bottom: 5px;">
                {{ item.supplier.state }} - {{ item.supplier.zipcode }}
              </p>
            </div>

            <div v-if="item.supplier.note" class="col-md-12">
              <p class="mt-2">
                Observação do:
                {{ item.supplier.note }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="item.customer">
          <div class="form-row">
            <div class="col-md-5">
              <p style="margin-bottom: 5px;">
                {{ item.customer.full_name }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ toMaskCpf(item.customer.cpf) }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ item.customer.birth | datePT() }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ item.customer.email }}
              </p>
              <p style="margin-bottom: 5px;">
                {{ item.customer.cellphone }}
              </p>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="col-md-8">
            <h5 class="mb-1"><strong>OBSERVAÇÃO</strong></h5>
            {{ item.description ? item.description : 'Nenhuma até o momento.' }}
          </div>

          <div class="col-md-4">
            <div class="text-center">
              <strong style="margin-bottom: 8px;">VALOR TOTAL DO PEDIDO: </strong>
              <h4 class="text-center">
                <strong>{{ item.total | toCurrency }}</strong>
              </h4>
              <small class="text-center d-block">
                (parcelado em {{ item.installments }}x)
              </small>
            </div>
          </div>
        </div>
        <hr>
        <div class="row mt-2">
          <div class="col-md-7">
            <h5 class="mb-1"><strong>RATEIO</strong></h5>
            <div v-for="(element, index) in item.strategic_units" :key="index" class="form-row align-items-center"
              :class="{ 'mb-1': index !== item.strategic_units - 1 }">
              <div class="col-md-7">
                <div class="form-group mb-0">
                  {{ element.strategic_unit.title }}
                </div>
              </div>

              <div class="col-md-3  text-center">
                <div class="form-group mb-0 text-center">
                  {{ element.total | toCurrency }}
                </div>
              </div>

              <div class="col-md-2 text-center">
                <div class="form-group mb-0">
                  {{ element.percent }}%
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-row align-items-center mt-1" :class="{ 'mb-1': i !== element.business.length - 1 }"
                  v-for="(el, i) in element.business" :key="`business-strategic-key-${i}`">
                  <div class="col-md-7">
                    <div class="form-group mb-0">
                      <i class="bi bi-arrow-return-right"></i> {{ el.business_unit.title }}
                    </div>
                  </div>
                  <div class="col-md-3 text-center">
                    <div class="form-group mb-0">
                      {{ el.total | toCurrency }}
                    </div>
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="form-group mb-0">
                      {{ el.percent }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <h5 class="mb-1"><strong>PAGAMENTOS</strong></h5>
            <ul v-if="item.all_installments && item.all_installments.length" class="list-group list-group-flush"
              style="font-size: 0.9rem">
              <li class="list-group-item px-0 px-1" v-for="(item, index) in item.all_installments"
                :key="`li-installment-${index}`">
                <div v-b-tooltip.hover.top="`Status: ${item.status}`" class="d-flex"
                  style="justify-content: space-between; align-items: center;">
                  <div>
                    <i :class="`bi ${item.icon}`"></i>
                    {{ item.installment }}x de {{ item.total | toCurrency }}
                  </div>
                  <div>
                    {{ item.date | datePT }}
                  </div>
                </div>
              </li>
            </ul>

            <!-- <hr>
            <h4 class="mt-2">Arquivos</h4>
            <div class="form-row align-items-center mt-1" v-if="item.files.length"
              style="align-items: center;font-size: 0.9rem;" v-for="(file, index) in item.files">
              <div class="col-md-5 text-left">
                <a target="_blank" :href="file.url" title="Acessar arquivo">
                  <i class="bi bi-cloud-download"></i> Arquivo {{ index + 1 }}
                </a>
              </div>
              <div class="col-md-3 text-left">
                {{ file.extension }}
              </div>
              <div class="col-md-4 text-center">
                {{ file.size | bytesToMB(2) }}
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="form-row justify-content-end modal-footer-custom">
        <div class="col-md-2 col-6">
          <button type="button" @click="$bvModal.hide('modal-purchase-order-view')"
            class="btn btn-block btn-light float-left">
            Fechar
          </button>
        </div>
        <div class="col-md-2 col-6">
          <button stype="button" class="btn-block btn btn-primary">
            IMPRIMIR
          </button>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  VBTooltip,
  BForm,
  BModal,
  BFormCheckbox
} from "bootstrap-vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { required } from "vuelidate/lib/validators";
import Ripple from 'vue-ripple-directive'
import { maskCpf } from "@/_helpers/_urlHelper";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    BCardHeader,
    BCardTitle,
    BModal,
    BCardActions,
    BCardSubTitle,
    BFormCheckbox,
    BCardText,
    CustomPaginateTable,
    BForm,
    ButtonsActionsFooter,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedItems: [],
      selectAllCheckbox: false,
      installmentsTable: [
        {
          key: "installment",
          label: "Parcela",
          class: "text-center",
          thStyle: "width: 50px",
        },
        {
          key: "date",
          label: "Vencimento",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "total",
          label: "Valor",
          class: "text-center",
          thStyle: "width: 200px",
        },
      ],
      submited: false,
      textSubmit: '',
      submitedFilter: false,
      filters: {
        company: '',
        status: '',
        bank_account: '',
        document_number: '',
        period: '',
        currentPage: 1,
        perPage: 100,
        id: '',
      },
      titleModal: '',
      optionsStatus: [],
      optionsBankAccounts: [],
      optionsPurchaseOrders: [],
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        { key: "select", label: "", class: "text-center", thStyle: { width: "20px" } },
        { key: "id", label: "ID", class: "text-center", thStyle: { width: "50px" } },
        { key: "document", label: "Documento", class: "text-center", thStyle: { width: "140px" } },
        { key: "date", label: "Vencimento", class: "text-center", thStyle: { width: "130px" } },
        { key: "purchase_order_installment_status_name", label: "Situação", class: "text-center", thStyle: { width: "130px" } },
        { key: "installment", label: "Parcela", class: "text-center", thStyle: { width: "250px" } },
        { key: "supplier", label: "Fornecedor", class: "text-center", thStyle: { width: "250px" } },
        { key: "plan", label: "Histórico PC", class: "text-center", thStyle: { width: "230px" } },
        { key: "total", label: "Total", class: "text-center", thStyle: { width: "130px" } },
        { key: "actions", label: "", class: "text-center", thStyle: { width: "40px" } },
      ],
      dataItems: [],
      formModal: {
        note: '',
        document_number: '',
        document_type: '',
        total: '',
        bank_account: '',
        status: ''
      },
      item: {
        tags: [],
        supplier: "",
        issue_date: "",
        total: "",
        due_date: "",
        category: "",
        document_number: "",
        occurrence: "",
        note: "",
        document_type: "",
        plan: "",
        description: "",
        strategic_units: [],
        installments: "",
        installment_first: "",
        installment_due: "",
        bank_account: "",
        status: "",
        step: "",
        user: "",
        files: [],
      },
    };
  },
  validations: {
    formModal: {
      document_number: {
        required,
      },
      document_type: {
        required,
      },
      bank_account: {
        required,
      },
      status: {
        required,
      }
    }
  },
  watch: {
    selectedItems(newVal) {
      if (newVal.length === 0 && this.dataItems.length === 0) {
        this.selectAllCheckbox = false
      } else {
        this.selectAllCheckbox = newVal.length === this.dataItems.length;
      }
    },
  },
  methods: {
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems.map(item => item.uuid)
        : [];
    },
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    async openModal(uuid) {
      this.$store.dispatch("OrderPurchase/edit", uuid).then((res) => {
        this.item = res
        this.$bvModal.show('modal-purchase-order-view');
      })
    },
    selectAllChanged(value) {
      if (value) {
        this.selectedItems = this.dataItems.map(item => item.id);
      } else {
        this.selectedItems = [];
      }
    },
    updateInstallmentStatus(status) {
      this.$swal({
        title: "Aprovar solicitação de baixa?",
        text: "Ao confirmar, você estará solicitando a baixa do pagamento.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("OrderPurchase/updateInstallmentStatus", { items: this.selectedItems, status }).then(() => {
            this.notifyDefault("success");
            this.getData()
          })
        }
      });
    },
    async fetchPurchaseOrders(term) {
      if (term.length > 2) {
        this.$store.dispatch("OrderPurchase/forSelectOfSteps", { ids: [2, 3], term: term }).then((res) => {
          this.optionsPurchaseOrders = res
        });
      }
    },
    async getData() {
      this.selectedItems = []
      const filtersData = {
        status: this.filters.status ? this.filters.status.code : '',
        bank_account: this.filters.bank_account ? this.filters.bank_account.code : '',
        company: this.filters.company,
        period: this.filters.period,
        id: this.filters.id,
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      }

      this.$store.dispatch("OrderPurchase/allPaymentAnalysis", filtersData).then((data) => {
        if (data) {
          this.dataItems = data.data;
          this.rowsTable = data.data.length;
          this.totalRows = data.meta.total;
          this.filters.currentPage = data.meta.current_page;

          // for (let a = 0; a < this.dataItems.length; a++) {
          //   for (let n = 0; n < this.optionsSteps.length; n++) {
          //     if (this.dataItems[a].step.title === this.optionsSteps[n].title) {
          //       this.dataItems[a].step = {
          //         current: this.dataItems[a].step,
          //         next: {
          //           title: this.optionsSteps[n + 1].title,
          //           user_name: this.optionsSteps[n + 1].user_name
          //         },
          //       }
          //     }
          //   }
          // }
        }
      });
    },
  },
  async mounted() {
    this.getData();

    this.$store.dispatch("BankAccount/forSelect", { term: '' }).then((res) => {
      this.optionsBankAccounts = res
    })

    this.$store.dispatch("OrderPurchase/forSelectInstallmentStatus", { ids: [2, 3] }).then((res) => {
      this.optionsStatus = res
    })
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-order-purchase-payments");
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss" scoped>
.strategic-units {
  align-items: center;
  display: grid;
  align-content: center;
  justify-content: left;
  display: block;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
