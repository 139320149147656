var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("div", { staticClass: "form-group mb-md-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.id,
                          expression: "filters.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "ID" },
                      domProps: { value: _vm.filters.id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group mb-md-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.company,
                          expression: "filters.company",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "Fornecedor" },
                      domProps: { value: _vm.filters.company },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "company", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsStatus,
                            placeholder: "Status",
                          },
                          model: {
                            value: _vm.filters.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "status", $$v)
                            },
                            expression: "filters.status",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsBankAccounts,
                            placeholder: "Conta bancária",
                          },
                          model: {
                            value: _vm.filters.bank_account,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "bank_account", $$v)
                            },
                            expression: "filters.bank_account",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Período de vencimento",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.submitedFilter
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("i", {
                              staticClass: "bi bi-search cursor-pointer cursor",
                            }),
                          ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-row justify-content-end mb-2" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _vm._v(
            " Ao aprovar uma baixa, o mesmo será liberado para ser dado baixa. "
          ),
        ]),
        _c("div", { staticClass: "col-md-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-block",
              attrs: {
                disabled: _vm.selectedItems.length < 1 ? true : false,
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.updateInstallmentStatus(3)
                },
              },
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.top",
                    value: "Dar baixa",
                    expression: "'Dar baixa'",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "bi bi-receipt-cutoff cursor-pointer cursor mr-1",
                staticStyle: { "font-size": "1.2rem", "line-height": "1.5" },
              }),
              _vm._v(" Aprovar baixa "),
            ]
          ),
        ]),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "head(select)",
                fn: function () {
                  return [
                    _c("b-form-checkbox", {
                      on: { change: _vm.toggleSelectAll },
                      model: {
                        value: _vm.selectAllCheckbox,
                        callback: function ($$v) {
                          _vm.selectAllCheckbox = $$v
                        },
                        expression: "selectAllCheckbox",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(select)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.purchase_order_installment_status_name === "Análise"
                      ? _c("b-form-checkbox", {
                          staticStyle: {
                            "margin-left": "13px",
                            padding: "0px",
                            width: "20px",
                            margin: "auto",
                          },
                          attrs: { value: item.uuid },
                          model: {
                            value: _vm.selectedItems,
                            callback: function ($$v) {
                              _vm.selectedItems = $$v
                            },
                            expression: "selectedItems",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(supplier)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.supplier
                      ? _c("div", { staticClass: "strategic-units" }, [
                          _c("strong", [
                            _vm._v(_vm._s(item.supplier.company_name)),
                          ]),
                          _c("div", [_vm._v(_vm._s(item.supplier.document))]),
                        ])
                      : _c("div", { staticClass: "strategic-units" }, [
                          _c("strong", [_vm._v(_vm._s(item.customer.title))]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.toMaskCpf(item.customer.cpf))),
                          ]),
                        ]),
                  ]
                },
              },
              {
                key: "cell(installment)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.installment) +
                        "/" +
                        _vm._s(item.installments) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(purchase_order_installment_status_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        style:
                          "background-color: " +
                          item.purchase_order_installment_status_background +
                          "; color: " +
                          item.purchase_order_installment_status_color +
                          ";padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.purchase_order_installment_status_name
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(document)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.document
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "grid",
                              "align-content": "center",
                              "justify-content": "center",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(" " + _vm._s(item.document.number) + " "),
                            ]),
                            _c("div", [_vm._v(_vm._s(item.document.title))]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(plan)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.plan
                      ? _c("div", { staticClass: "d-block text-center" }, [
                          _c("strong", [_vm._v(_vm._s(item.plan.title))]),
                          item.document_type
                            ? _c("div", [
                                _vm._v(_vm._s(item.document_type.title)),
                              ])
                            : _vm._e(),
                        ])
                      : item.order_id
                      ? _c("span", { staticClass: "text-danger" }, [
                          _c("strong", { staticClass: "d-block" }, [
                            _vm._v("Matrícula"),
                          ]),
                          _vm._v(" Cancelada "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(date)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm._f("datePT")(item.date)) + " "),
                  ]
                },
              },
              {
                key: "cell(total)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(item.total)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.$can("Lançado - Visualizar", "Financeiro - ADM")
                      ? _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Visualizar",
                              expression: "'Visualizar'",
                              modifiers: { hover: true, top: true },
                            },
                          ],
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EyeIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "orders-purchases-print",
                                params: { uuid: item.purchase_order_uuid },
                              })
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-purchase-order-view",
            size: "lg",
            "modal-class": "modal-create",
            title: "Detalhes do pedido de compra",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                " bg-white p-2 rounded-1 justify-content-center mt-1 d-block",
            },
            [
              _c("h4", { staticClass: "text-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: {
                      "align-content": "center",
                      "justify-content": "space-between",
                      "align-items": "center",
                    },
                  },
                  [
                    _c("strong", [_vm._v("ID " + _vm._s(_vm.item.id))]),
                    _c("strong", [_vm._v("PEDIDO DE COMPRA ")]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm._f("datePT")(_vm.item.created_at, true))
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("hr"),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("h5", { staticClass: "mb-1" }, [
                    _c("strong", [_vm._v("DETALHES")]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-8" }, [
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _c("strong", [_vm._v("Tipo doc.")]),
                    _vm._v(" " + _vm._s(_vm.item.document_type.title) + " "),
                  ]),
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _c("strong", [_vm._v(" Categoria ")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.item.category
                            ? _vm.item.category.title
                            : "Nenhuma"
                        ) +
                        " "
                    ),
                  ]),
                  _vm.item.plan
                    ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                        _c("strong", [_vm._v("Histórico PC")]),
                        _vm._v(" " + _vm._s(_vm.item.plan.title) + " "),
                      ])
                    : _vm._e(),
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _c("strong", [_vm._v("Nº Documento")]),
                    _vm._v(" " + _vm._s(_vm.item.document_number) + " "),
                  ]),
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _c("strong", [_vm._v("Status")]),
                    _vm._v(" " + _vm._s(_vm.item.status.title) + " "),
                  ]),
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _c("strong", [_vm._v("Etapa")]),
                    _vm._v(" " + _vm._s(_vm.item.step.title) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _vm.item.bank_account
                    ? _c("div", [
                        _c("h5", [_vm._v("Conta bancária")]),
                        _vm.item.bank_account.title
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("strong", [_vm._v("Empresa:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.bank_account.company) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.bank_account.title
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("strong", [_vm._v("Nome:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.bank_account.title) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.bank_account.agency
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("strong", [_vm._v("Agência:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.bank_account.agency) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.bank_account.account_number
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("strong", [_vm._v("Nº Conta:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.item.bank_account.account_number
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.bank_account.bank_name
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _c("strong", [_vm._v("Banco:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.bank_account.bank_name) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("hr"),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("h5", { staticClass: "mb-1" }, [
                    _c("strong", [_vm._v("Fornecedor")]),
                  ]),
                ]),
              ]),
              _vm.item.supplier
                ? _c("div", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(" " + _vm._s(_vm.item.supplier.title) + " "),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.item.supplier.trading_name) + " "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.item.supplier.document) + " "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " Simples Nacional: " +
                              _vm._s(
                                _vm.item.supplier.simple_national
                                  ? "Sim"
                                  : "Não"
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.item.supplier.type_company) + " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("h5", [_vm._v("Contatos")]),
                        _vm.item.supplier.email
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.item.supplier.email) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.supplier.whatsapp
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.item.supplier.whatsapp) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.supplier.cellphone
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.supplier.cellphone) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.supplier.phone
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.item.supplier.phone) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("h5", [_vm._v("Endereço")]),
                        _vm.item.supplier.address
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.item.supplier.address) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.supplier.number
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " Nº " +
                                    _vm._s(_vm.item.supplier.number) +
                                    " " +
                                    _vm._s(_vm.item.supplier.complement) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.supplier.neighborhood
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.supplier.neighborhood) +
                                    ", " +
                                    _vm._s(_vm.item.supplier.city) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.item.supplier.zipcode
                          ? _c(
                              "p",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.supplier.state) +
                                    " - " +
                                    _vm._s(_vm.item.supplier.zipcode) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.item.supplier.note
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                " Observação do: " +
                                  _vm._s(_vm.item.supplier.note)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm.item.customer
                ? _c("div", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.item.customer.full_name) + " "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.toMaskCpf(_vm.item.customer.cpf)) +
                              " "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datePT")(_vm.item.customer.birth)
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(" " + _vm._s(_vm.item.customer.email) + " "),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.item.customer.cellphone) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("hr"),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c("h5", { staticClass: "mb-1" }, [
                    _c("strong", [_vm._v("OBSERVAÇÃO")]),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.item.description
                          ? _vm.item.description
                          : "Nenhuma até o momento."
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "text-center" }, [
                    _c("strong", { staticStyle: { "margin-bottom": "8px" } }, [
                      _vm._v("VALOR TOTAL DO PEDIDO: "),
                    ]),
                    _c("h4", { staticClass: "text-center" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("toCurrency")(_vm.item.total))),
                      ]),
                    ]),
                    _c("small", { staticClass: "text-center d-block" }, [
                      _vm._v(
                        " (parcelado em " +
                          _vm._s(_vm.item.installments) +
                          "x) "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("hr"),
              _c("div", { staticClass: "row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("h5", { staticClass: "mb-1" }, [
                      _c("strong", [_vm._v("RATEIO")]),
                    ]),
                    _vm._l(_vm.item.strategic_units, function (element, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "form-row align-items-center",
                          class: {
                            "mb-1": index !== _vm.item.strategic_units - 1,
                          },
                        },
                        [
                          _c("div", { staticClass: "col-md-7" }, [
                            _c("div", { staticClass: "form-group mb-0" }, [
                              _vm._v(
                                " " + _vm._s(element.strategic_unit.title) + " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3  text-center" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0 text-center" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toCurrency")(element.total)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-2 text-center" }, [
                            _c("div", { staticClass: "form-group mb-0" }, [
                              _vm._v(" " + _vm._s(element.percent) + "% "),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            _vm._l(element.business, function (el, i) {
                              return _c(
                                "div",
                                {
                                  key: "business-strategic-key-" + i,
                                  staticClass:
                                    "form-row align-items-center mt-1",
                                  class: {
                                    "mb-1": i !== element.business.length - 1,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group mb-0" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-arrow-return-right",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(el.business_unit.title) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-3 text-center" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toCurrency")(el.total)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2 text-center" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(el.percent) + "% "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c("div", { staticClass: "col-md-5" }, [
                  _c("h5", { staticClass: "mb-1" }, [
                    _c("strong", [_vm._v("PAGAMENTOS")]),
                  ]),
                  _vm.item.all_installments && _vm.item.all_installments.length
                    ? _c(
                        "ul",
                        {
                          staticClass: "list-group list-group-flush",
                          staticStyle: { "font-size": "0.9rem" },
                        },
                        _vm._l(
                          _vm.item.all_installments,
                          function (item, index) {
                            return _c(
                              "li",
                              {
                                key: "li-installment-" + index,
                                staticClass: "list-group-item px-0 px-1",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Status: " + item.status,
                                        expression: "`Status: ${item.status}`",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass: "d-flex",
                                    staticStyle: {
                                      "justify-content": "space-between",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { class: "bi " + item.icon }),
                                      _vm._v(
                                        " " +
                                          _vm._s(item.installment) +
                                          "x de " +
                                          _vm._s(
                                            _vm._f("toCurrency")(item.total)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("datePT")(item.date)) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "form-row justify-content-end modal-footer-custom" },
            [
              _c("div", { staticClass: "col-md-2 col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-light float-left",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.hide("modal-purchase-order-view")
                      },
                    },
                  },
                  [_vm._v(" Fechar ")]
                ),
              ]),
              _c("div", { staticClass: "col-md-2 col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-block btn btn-primary",
                    attrs: { stype: "button" },
                  },
                  [_vm._v(" IMPRIMIR ")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }